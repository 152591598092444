.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::selection {
  background: #8398a7;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(103, 127, 144, 0.1);
  box-sizing: border-box;
}
@media (min-width: 61.875rem) {
  * {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((1.0625rem + 0.15625 * (100vw - 20rem) / (73.75)) / 1000 * 20);
  font-size: 1.0625rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #707173;
  font-family: "Lato", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.8;
  margin: 0 auto;
  max-width: 160rem;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(1.0625rem + 0.15625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1.21875rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
.style_h1,
.style_h2,
.style_h3,
.style_h4,
.style_h5,
.style_h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1.75rem + 1 * (100vw - 20rem) / (73.75)) / 1000 * 10);
  font-size: 1.75rem;
  color: #677F90;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.9em;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.75rem + 1 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 2.75rem;
  }
}
h2,
.style_h2 {
  letter-spacing: calc((1.5rem + 0.875 * (100vw - 20rem) / (73.75)) / 1000 * 20);
  font-size: 1.5rem;
  color: #677F90;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.9em;
  padding-top: 0.9em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.5rem + 0.875 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 2.375rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1.25rem + 0.4375 * (100vw - 20rem) / (73.75)) / 1000 * 20);
  font-size: 1.25rem;
  color: #677F90;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.9em;
  padding-top: 0.9em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1.25rem + 0.4375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.6875rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((1.125rem + 0.375 * (100vw - 20rem) / (73.75)) / 1000 * 20);
  font-size: 1.125rem;
  color: #677F90;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.9em;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(1.125rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4,
  .style_h4 {
    font-size: 1.5rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 20);
  font-size: 0.875rem;
  color: #677F90;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 1rem;
  }
}
h6,
.style_h6 {
  letter-spacing: calc((0.75rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 20);
  font-size: 0.75rem;
  color: #677F90;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h6,
  .style_h6 {
    font-size: calc(0.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h6,
  .style_h6 {
    font-size: 0.875rem;
  }
}
p {
  margin: 0 0 0.9em;
}
a {
  color: #677F90;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #707173;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(103, 127, 144, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"] {
  display: block;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #707173;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #677F90;
  display: inline-block;
  margin-bottom: 0.45em;
  outline-offset: 0;
  padding: 0;
  text-decoration: none;
  -webkit-transition: color 300ms ease-in-out;
  transition: color 300ms ease-in-out;
}
a.touch_link .title.active {
  color: #707173;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(103, 127, 144, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.9em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.8em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.8em;
}
table {
  border-bottom: 0.0625rem solid #d1d1d1;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #d1d1d1;
  padding: 0.6em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #eaeaea;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.9em;
  padding: 0;
}
ol > li {
  padding-left: 1.8em;
  position: relative;
}
ol > li:before {
  color: #677F90;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.8;
  position: absolute;
  text-align: right;
  width: 1.35em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg") !important;
  margin: 0 0 0.9em;
  padding-left: 1.35em;
}
ul > li {
  padding-left: 0.45em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #d1d1d1;
  margin: 0.9em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #d1d1d1;
  font-weight: 700;
  padding: 0.9em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.9em 0.9em 0.9em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.0625rem solid #d1d1d1;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.9em 0.6em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.8em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.8em;
}
figure.floatright {
  float: right;
  margin-left: 1.8em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.45em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 400;
  letter-spacing: calc((1.0625rem + 0.15625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.0625rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(1.0625rem + 0.15625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1.21875rem;
  }
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #707173;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #677F90 inset, 0 0 0 0.1875rem rgba(103, 127, 144, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #b0b1b2;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #b0b1b2;
  -ms-user-select: none;
      user-select: none;
}
::-ms-input-placeholder {
  color: #b0b1b2;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #b0b1b2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #677F90;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #677F90;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.45em;
  padding: 0 0.45em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 20);
  font-size: 1.1875rem;
  color: #707173;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.9em;
  padding: 0.9em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.45em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0;
  box-shadow: 0 0 0 #d1d1d1 inset;
  color: #707173;
  margin: 0 0 1.2em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #677F90;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #677F90;
  box-shadow: 0 0 0.1875rem #677F90 inset, 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
  color: #707173;
}
.input:focus,
.textarea:focus {
  border-color: #677F90;
  box-shadow: 0 0 0.1875rem #677F90 inset, 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
  color: #707173;
}
.input {
  padding: 0 0.9em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  height: 2.75rem;
}
@media (min-width: 20rem) {
  .input {
    height: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 3rem;
  }
}
.textarea {
  line-height: 1.8;
  overflow-x: hidden;
  padding: 0.9em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.45em 0.9em 0.9em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #677F90;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #677F90;
  box-shadow: 0 0 0.1875rem #677F90 inset, 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
  color: #707173;
}
.control input:focus ~ .indicator {
  border-color: #677F90;
  box-shadow: 0 0 0.1875rem #677F90 inset, 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
  color: #707173;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.9em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #707173;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  align-content: center;
  background: #fff;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0;
  box-shadow: 0 0 0.1875rem #d1d1d1 inset;
  color: #707173;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  margin: 0 0 1.2em;
  padding: 0 0.9em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #707173;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.9em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #677F90;
}
.select.error.focus {
  border-color: #677F90;
  box-shadow: 0 0 0.1875rem #677F90 inset, 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
  color: #707173;
}
.select.focus {
  border-color: #677F90;
  box-shadow: 0 0 0.1875rem #677F90 inset, 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
  color: #707173;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #707173;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select {
    min-height: calc(2.625rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 2.875rem;
  }
}
select option {
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.625rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 2.875rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #707173;
}
select:focus::-ms-value {
  background: none;
  color: #707173;
}
@-webkit-keyframes scroll_down_animation {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes scroll_down_animation {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes intro_text {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes intro_text {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes blue_liner_first {
  0% {
    height: 0;
  }
  100% {
    height: 55%;
  }
}
@keyframes blue_liner_first {
  0% {
    height: 0;
  }
  100% {
    height: 55%;
  }
}
@-webkit-keyframes blue_liner_second {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes blue_liner_second {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes blue_liner_third {
  0% {
    height: 0;
  }
  100% {
    height: 45%;
  }
}
@keyframes blue_liner_third {
  0% {
    height: 0;
  }
  100% {
    height: 45%;
  }
}
@-webkit-keyframes liner {
  0% {
    left: 0;
    opacity: 1;
    right: 100%;
  }
  45% {
    right: 0;
  }
  55% {
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 1;
    right: 0;
  }
}
@keyframes liner {
  0% {
    left: 0;
    opacity: 1;
    right: 100%;
  }
  45% {
    right: 0;
  }
  55% {
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 1;
    right: 0;
  }
}
@-webkit-keyframes header_image {
  0% {
    -webkit-transform: rotate(0.01deg) scale(1.06);
            transform: rotate(0.01deg) scale(1.06);
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
  }
}
@keyframes header_image {
  0% {
    -webkit-transform: rotate(0.01deg) scale(1.06);
            transform: rotate(0.01deg) scale(1.06);
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
  }
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(103, 127, 144, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(103, 127, 144, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(103, 127, 144, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(103, 127, 144, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .app_store_overview {
    margin-top: 1.8em;
  }
  .app_store_overview a {
    display: inline-block;
    text-decoration: none;
  }
  .app_store_overview a figure {
    margin: 0;
  }
  .app_store_overview a::after {
    display: none;
  }
  .app_store_overview a:hover,
  .app_store_overview a:focus {
    text-decoration: none;
  }
  .job_overview {
    margin-top: 1.8em;
  }
  .job_overview .touch_link {
    position: relative;
    z-index: 0;
  }
  .job_overview .touch_link::after {
    background-color: #eaeaea;
    bottom: -0.9em;
    content: "";
    left: -0.9em;
    opacity: 0;
    position: absolute;
    right: -0.9em;
    top: -0.9em;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    z-index: -1;
  }
  .job_overview .touch_link:hover::after,
  .job_overview .touch_link:focus::after {
    opacity: 1;
  }
  .job_overview .entry {
    margin-bottom: 1.8em;
    border-left: 0.0625rem solid #677F90;
    padding-left: 0.9em;
  }
  .job_overview .entry .title {
    margin: 0;
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.7em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    font-weight: 400;
  }
  .address_vcard_button {
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.54em 0.9em 0;
  }
  .address_vcard_button .icon {
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .address_vcard_button:focus .icon,
  .address_vcard_button:hover .icon {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
  .address_call_button {
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.54em 0.9em 0;
  }
  .address_call_button .icon {
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .address_call_button:focus .icon,
  .address_call_button:hover .icon {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }
  .atikon_logo {
    height: 100%;
  }
  .author_hor {
    right: 0.375rem;
    top: 0.1875rem;
    display: inline-block;
    fill: #a0a0a0;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    width: 4.6875rem;
  }
  .background_image_overview {
    bottom: 0;
    position: absolute;
    top: 0;
    -webkit-transition: margin-top 300ms ease-in-out;
    transition: margin-top 300ms ease-in-out;
    width: 100%;
  }
  .background_image_overview .background_image_wrapper {
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms ease-in-out;
    transition: opacity 600ms ease-in-out;
    width: 100%;
    z-index: 1;
  }
  .background_image_overview .background_image_wrapper.show {
    opacity: 1;
    pointer-events: auto;
  }
  .background_image_overview .background_image_wrapper.show .background_image {
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: header_image;
            animation-name: header_image;
  }
  .background_image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.9em;
  }
  .breadcrumbs p {
    margin: 0 0.45em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.45em;
  }
  .button {
    border-radius: 0;
    padding: 0 1.8em;
    background: #677F90;
    border: 0.0625rem solid #fff;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 300;
    padding: 0.45em 0;
  }
  .button .icon + .text {
    padding-left: 0.6em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
    outline: 0;
    background: #fff;
    border-color: #677F90;
    color: #566A77;
  }
  .button:focus .icon {
    fill: #566A77;
  }
  .button:hover {
    background: #fff;
    border-color: #677F90;
    color: #566A77;
  }
  .button:hover .icon {
    fill: #566A77;
  }
  .button.active {
    box-shadow: 0 0 0.1875rem #677F90 inset;
    background: #677F90;
    border: 0.0625rem solid #677F90;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 1.5rem;
  }
  .button.active .text {
    font-weight: 300;
    padding: 0.45em 0;
  }
  .button.active .icon + .text {
    padding-left: 0.6em;
  }
  .button.bottom_spacing {
    margin-bottom: 0.9em;
  }
  .button_overview {
    display: -webkit-box;
    display: flex;
  }
  .button_overview .button {
    margin-right: 0.9em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #677F90;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.45em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #677F90;
    height: auto;
    left: 0.45em;
    top: 0.45em;
    width: auto;
  }
  .company_logo {
    width: 14.0625rem;
    display: block;
    margin: 1.08em 0 1.8em 0;
    position: relative;
    -webkit-transition: width 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: width 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: width 300ms ease-in-out, transform 300ms ease-in-out;
    transition: width 300ms ease-in-out, transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    z-index: 0;
  }
  .company_logo::after {
    background-color: #677F90;
    bottom: -0.9em;
    content: "";
    height: 0.125rem;
    left: -62.4375rem;
    position: absolute;
    right: -62.4375rem;
    z-index: -1;
  }
  .company_logo:hover,
  .company_logo:focus {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #eaeaea;
    border-top: 0.0625rem solid #d1d1d1;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1.2em 0.9em 1.2em 1.8em;
    text-align: center;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin-bottom: 0.9em;
    padding: 0 0.9em;
  }
  .cookie_banner .close > span {
    min-height: 2.625rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.36em;
  }
  .download_overview {
    margin: 3.6em 0;
    position: relative;
  }
  .download_overview .overview_title {
    padding-top: 0;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
    margin-top: 0.9em;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0;
    padding-top: 0;
  }
  .download_overview .touch_link p {
    margin-bottom: 0;
  }
  .download_overview .touch_link .icon {
    fill: #677F90;
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.9em;
    margin-top: 0.27em;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 1.5rem;
  }
  .download_overview .touch_link:hover .icon_xls,
  .download_overview .touch_link:focus .icon_xls {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
  .download_overview .touch_link:hover .icon_online,
  .download_overview .touch_link:focus .icon_online {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul li {
    position: relative;
  }
  .dropdown_menu > ul li:hover > a,
  .dropdown_menu > ul li.hover > a {
    color: #fff;
  }
  .dropdown_menu > ul li:hover > a::after,
  .dropdown_menu > ul li.hover > a::after {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    visibility: visible;
  }
  .dropdown_menu > ul li:hover > ul,
  .dropdown_menu > ul li.hover > ul {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    visibility: visible;
    z-index: 1001;
  }
  .dropdown_menu > ul li .icon {
    box-sizing: content-box;
    display: inline-block;
    height: 0.625rem;
    padding-left: 0.9em;
    width: 0.625rem;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li > a {
    letter-spacing: calc((0.8125rem + 0.40625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 0.8125rem;
    -webkit-box-align: center;
            align-items: center;
    color: #707173;
    display: -webkit-box;
    display: flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.63em 0.9em;
    position: relative;
    text-decoration: none;
    -webkit-transition: color 150ms ease-in-out;
    transition: color 150ms ease-in-out;
    white-space: nowrap;
    z-index: 0;
  }
  .dropdown_menu > ul > li > a::after {
    background-color: #566A77;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    -webkit-transition: visibility 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: visibility 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, visibility 300ms ease-in-out;
    transition: transform 300ms ease-in-out, visibility 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    visibility: hidden;
    z-index: -1;
  }
  .dropdown_menu > ul > li > a.active {
    font-weight: 700;
  }
  .dropdown_menu > ul > li ul {
    background: #566A77;
    margin-top: -0.0625rem;
    padding: 1.44em 2.7em;
    position: absolute;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transition: visibility 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: visibility 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, visibility 300ms ease-in-out;
    transition: transform 300ms ease-in-out, visibility 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 300;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 0;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    z-index: 0;
  }
  .dropdown_menu > ul > li ul a::after {
    background-color: #d1d1d1;
    bottom: 0.0625rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 100%;
    -webkit-transition: visibility 300ms ease-in-out;
    transition: visibility 300ms ease-in-out;
    visibility: hidden;
  }
  .dropdown_menu > ul > li ul a:focus::after,
  .dropdown_menu > ul > li ul a:hover::after {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: liner;
            animation-name: liner;
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    font-weight: 700;
  }
  footer .footer_content .addition {
    display: block;
  }
  footer .footer_content .contact_wrapper {
    display: block;
  }
  footer .footer_content .contact_wrapper p {
    margin-right: 0;
  }
  footer .footer_content .contact_wrapper .postal_address > span {
    display: block;
  }
  footer .footer_content .contact strong {
    font-weight: 300;
  }
  footer .footer_content .company_name {
    font-weight: 400;
  }
  footer .footer_content .postal_address {
    margin: 2.16em 0;
  }
  footer .footer_content a {
    position: relative;
    text-decoration: none;
    z-index: 0;
  }
  footer .footer_content a::after {
    background-color: #d1d1d1;
    bottom: 0.25rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transition: right 300ms ease-in-out, visibility 300ms ease-in-out;
    transition: right 300ms ease-in-out, visibility 300ms ease-in-out;
    visibility: visible;
  }
  footer .footer_content a:focus::after,
  footer .footer_content a:hover::after {
    right: 100%;
    visibility: hidden;
  }
  footer .related_links ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 1.26em 0;
  }
  footer .related_links ul li {
    border-right: 0.0625rem solid #677F90;
    line-height: 1;
    margin-right: 0.54em;
    padding-right: 0.54em;
  }
  footer .related_links ul li:last-child {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
  }
  footer .related_links ul a {
    font-size: 85%;
    line-height: 1;
    position: relative;
    text-decoration: none;
    z-index: 0;
  }
  footer .related_links ul a::after {
    background-color: #677F90;
    bottom: 0.0625rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transition: left 300ms ease-in-out, right 300ms ease-in-out, visibility 300ms ease-in-out;
    transition: left 300ms ease-in-out, right 300ms ease-in-out, visibility 300ms ease-in-out;
    visibility: visible;
  }
  footer .related_links ul a:focus::after,
  footer .related_links ul a:hover::after {
    left: 50%;
    right: 50%;
    visibility: hidden;
  }
  footer .footer_content {
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: justify;
            justify-content: space-between;
    position: relative;
  }
  footer .footer_content.margin_top {
    margin-top: 2.7em;
  }
  footer .footer_content * {
    color: #fff;
  }
  footer .footer_content p {
    font-weight: 300;
  }
  footer .footer_content > .left {
    min-height: 21.25rem;
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 1.8em 0;
    width: 100%;
    z-index: 0;
  }
  footer .footer_content > .left .left_inner {
    max-width: 38rem;
  }
  footer .footer_content > .left::after {
    left: -1.875rem;
    background-color: #677F90;
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
  }
  footer .footer_content > .left .intro_text {
    display: none;
  }
  footer .footer_content > .left .intro_text::after {
    background-color: #fff;
  }
  footer .footer_content > .right {
    right: -1.875rem;
    margin-top: 0.0625rem;
    position: absolute;
  }
  .form_disclaimer {
    background: #eaeaea;
    border-radius: 0;
    color: #707173;
    font-size: 85%;
    margin-bottom: 0.9em;
    padding: 1.2em 1.8em;
    text-align: center;
  }
  .form_disclaimer a {
    display: block;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    margin: 0 0 1.2em;
    position: relative;
    height: 2.75rem;
  }
  .file.error .file_name {
    border-color: #677F90;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #d1d1d1;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0.1875rem #d1d1d1 inset;
    color: #707173;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    overflow: hidden;
    padding: 0 0.9em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
    line-height: 2.625rem;
    height: 2.75rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.8em;
    z-index: 10;
    background: #677F90;
    border: 0.0625rem solid #fff;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .file .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 300;
    padding: 0.45em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.6em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
    background: #fff;
    border-color: #677F90;
    color: #566A77;
  }
  .file .file_button.focus .icon {
    fill: #566A77;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    height: 2.75rem;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #fff;
    border-color: #677F90;
    color: #566A77;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #566A77;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #677F90 inset;
    background: #677F90;
    border: 0.0625rem solid #677F90;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 300;
    padding: 0.45em 0;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.6em;
  }
  button {
    cursor: pointer;
    margin: 0.9em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.8em;
    width: 100%;
    height: 2.75rem;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .google_maps {
    margin: 0 0 0.9em;
  }
  .google_maps .map {
    background: #eaeaea;
    border: 0.0625rem solid #d1d1d1;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.9em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #677F90;
    border-color: #677F90;
    color: #fff;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
    z-index: 15;
    background: #fff;
    border-color: #677F90;
    color: #566A77;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #566A77;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #d1d1d1;
    color: #707173;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #707173;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 300;
    padding: 0.45em 0;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.6em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #707173;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #677F90;
    font-size: 85%;
    margin-bottom: 0.9em;
    padding: 1.2em 1.8em;
  }
  .google_maps .direction {
    margin: 0.9em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.9em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #d1d1d1;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #d1d1d1;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.9em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.9em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.9em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  header {
    position: relative;
  }
  header .container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
    width: 100%;
  }
  header .second {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.08em;
  }
  header .login_portal,
  header .video_news {
    height: 2.75rem;
    margin-left: 1.44em;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 2.75rem;
  }
  header .login_portal .icon,
  header .video_news .icon {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    width: 100%;
  }
  header .login_portal .icon svg,
  header .video_news .icon svg {
    fill: #677F90;
    height: 2.5rem;
    width: 2.5rem;
  }
  header .login_portal:hover,
  header .video_news:hover,
  header .login_portal:focus,
  header .video_news:focus {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .intro_content {
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 2.7em;
    position: relative;
  }
  .intro_content * {
    color: #fff;
  }
  .intro_content p {
    font-weight: 300;
  }
  .intro_content > .left {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 1.8em 0;
    width: 100%;
    z-index: 0;
  }
  .intro_content > .left .left_inner {
    max-width: 63rem;
    width: 100%;
  }
  .intro_content > .left .left_inner p {
    width: 100%;
  }
  .intro_content > .left::after {
    left: -1.875rem;
    background-color: #677F90;
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
  }
  .intro_content > .left .button {
    margin-top: 1.8em;
  }
  .intro_content > .left .button .text {
    color: #fff;
  }
  .intro_content > .left .button:focus .text,
  .intro_content > .left .button:hover .text {
    color: #677F90;
  }
  .intro_content > .left .scroll_down_to_sidebar {
    display: -webkit-box;
    display: flex;
    fill: #fff;
    height: 1.625rem;
    margin-top: 3.6em;
    position: relative;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    -webkit-transform: translateY(-1.8em);
            transform: translateY(-1.8em);
    width: 3.1875rem;
  }
  .intro_content > .left .scroll_down_to_sidebar .icon {
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: scroll_down_animation;
            animation-name: scroll_down_animation;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .intro_content > .left .scroll_down_to_sidebar .icon:first-child {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  .intro_content > .left .scroll_down_to_sidebar .icon:last-child {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  .intro_content > .left .scroll_down_to_sidebar svg {
    -webkit-transform: translateY(1.8em);
            transform: translateY(1.8em);
  }
  .intro_content > .left .intro_text {
    display: none;
  }
  .intro_content > .left .intro_text::after {
    background-color: #fff;
  }
  .intro_content > .right {
    right: -1.875rem;
    margin-top: 0.0625rem;
    position: absolute;
  }
  .startpage .intro_content {
    margin-bottom: 0;
  }
  .infolist_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .infolist_overview {
    margin-top: 3.6em;
    position: relative;
  }
  .infolist_overview .overview_title {
    margin-bottom: 0;
    padding-top: 0;
  }
  .infolist_overview .touch_link {
    margin-top: 1.8em;
    position: relative;
    z-index: 0;
  }
  .infolist_overview .touch_link::after {
    background-color: #eaeaea;
    bottom: -0.9em;
    content: "";
    left: -0.9em;
    opacity: 0;
    position: absolute;
    right: -0.9em;
    top: -0.9em;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    z-index: -1;
  }
  .infolist_overview .touch_link .text {
    margin-bottom: 0;
  }
  .infolist_overview .touch_link:hover::after,
  .infolist_overview .touch_link:focus::after {
    opacity: 1;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 1.8em 0;
  }
  .infolist_top_link a {
    color: #707173;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
  }
  .infolist_top_link a::after {
    background-color: #707173;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .infolist_top_link a .icon {
    display: block;
    fill: #677F90;
    height: 2rem;
    margin: 0 auto;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 2rem;
  }
  .infolist_top_link a:focus::after,
  .infolist_top_link a:hover::after {
    left: 20%;
    opacity: 1;
    right: 20%;
  }
  .infolist_top_link a:focus .icon,
  .infolist_top_link a:hover .icon {
    -webkit-transform: rotate(-90deg) scale(1.2);
            transform: rotate(-90deg) scale(1.2);
  }
  .disclaimer {
    background: #eaeaea;
    border-radius: 0;
    color: #707173;
    font-size: 85%;
    margin-bottom: 0.9em;
    -webkit-box-ordinal-group: 7;
            order: 6;
    padding: 1.2em 1.8em;
    text-align: center;
  }
  .disclaimer a {
    display: block;
  }
  .infolist_download {
    -webkit-box-align: center;
            align-items: center;
    background: #677F90;
    border-radius: 0;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 1.8em;
    -webkit-box-ordinal-group: 5;
            order: 4;
    padding: 1.8em;
    text-align: center;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #d1d1d1;
    margin-bottom: 0.9em;
    padding-top: 0.9em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.9em;
  }
  .page_wrapper {
    overflow-x: hidden;
    position: relative;
  }
  .page_wrapper::after,
  .page_wrapper::before {
    background-color: #fff;
    content: "";
    height: 100%;
    position: fixed;
    top: 0;
    width: 50%;
    z-index: 10000;
  }
  .page_wrapper::after {
    left: calc(50% + 160rem / 2);
  }
  .page_wrapper::before {
    right: calc(50% + 160rem / 2);
  }
  .startpage h1 {
    max-width: 28.75rem;
    text-transform: uppercase;
  }
  .intro_text {
    bottom: 0;
    position: absolute;
    top: 0;
    width: 1.5rem;
    z-index: 0;
  }
  .intro_text .text {
    letter-spacing: calc((0.9375rem + 0.21875 * (100vw - 20rem) / (73.75)) / 1000 * 100);
    font-size: 0.9375rem;
    font-weight: 300;
    left: 0;
    line-height: 1rem;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 50%;
    -webkit-transform-origin: left top;
            transform-origin: left top;
    white-space: nowrap;
  }
  .intro_text::after {
    background-color: #707173;
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    width: 0.0625rem;
    z-index: -1;
  }
  .animated.intro_text::after {
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: intro_text;
            animation-name: intro_text;
  }
  .intro_text.left::after {
    right: 0;
  }
  .intro_text.left .text {
    -webkit-transform: rotate(270deg) translateX(-50%) translateY(0%);
            transform: rotate(270deg) translateX(-50%) translateY(0%);
  }
  .intro_text.right::after {
    left: 0;
  }
  .intro_text.right .text {
    -webkit-transform: rotate(90deg) translateX(-50%) translateY(-150%);
            transform: rotate(90deg) translateX(-50%) translateY(-150%);
  }
  .hidme_intro_text {
    letter-spacing: calc((0.875rem + 0.15625 * (100vw - 20rem) / (73.75)) / 1000 * 100);
    font-size: 0.875rem;
    display: block;
    padding: 0 0 0.36em 0;
    text-transform: uppercase;
  }
  .content_seperator {
    margin-top: 3.6em;
  }
  .content_seperator p {
    max-width: 81.25rem;
  }
  .intro_text_js_script {
    margin-top: 3.6em;
    position: relative;
  }
  .intro_text_js_script h2 {
    padding-top: 0;
  }
  .intro_text_js_script .video_overview {
    margin: 0;
  }
  .intro_text_js_script .digitale_kanzlei_label {
    display: -webkit-box;
    display: flex;
  }
  .actual_news_video {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    position: relative;
  }
  .actual_news_video .content {
    position: relative;
  }
  .actual_news_video .content .background_image {
    height: 17.5rem;
    left: 0;
    max-width: 78.125rem;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .actual_news_video .content .background_image .icon {
    height: 3.75rem;
    width: 3.75rem;
    fill: #677F90;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }
  .actual_news_video .content .background_image .icon svg {
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .actual_news_video .content .background_image:hover .icon svg,
  .actual_news_video .content .background_image:focus .icon svg {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  .actual_news_video .content .description_with_button {
    position: relative;
    z-index: 3;
  }
  .actual_news_video .content .description_with_button::after {
    background-color: #677F90;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .actual_news_video .content .description_with_button p,
  .actual_news_video .content .description_with_button h1,
  .actual_news_video .content .description_with_button h2,
  .actual_news_video .content .description_with_button h3,
  .actual_news_video .content .description_with_button h4,
  .actual_news_video .content .description_with_button h5 {
    color: #fff;
  }
  .actual_news_video .content .description_with_button p {
    font-weight: 300;
  }
  .actual_news_video .content .description_with_button .button {
    margin-top: 1.8em;
  }
  .actual_news_video .content .description {
    margin: 1.8em 0;
  }
  .actual_news_video .content h2,
  .actual_news_video .content h3,
  .actual_news_video .content h4,
  .actual_news_video .content h5 {
    padding-top: 0;
  }
  .liner {
    height: 6.25rem;
    width: 10rem;
    position: relative;
  }
  .liner .line {
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    background-color: #677F90;
    position: absolute;
  }
  .liner .first {
    height: 0;
    left: 0;
    top: 0;
    width: 0.25rem;
  }
  .animated.liner .first {
    -webkit-animation-name: blue_liner_first;
            animation-name: blue_liner_first;
  }
  .liner .second {
    height: 0.25rem;
    top: 55%;
    width: 0;
  }
  .animated.liner .second {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
    -webkit-animation-name: blue_liner_second;
            animation-name: blue_liner_second;
  }
  .liner .third {
    height: 0;
    right: 0;
    top: 55%;
    width: 0.25rem;
  }
  .animated.liner .third {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
    -webkit-animation-name: blue_liner_third;
            animation-name: blue_liner_third;
  }
  .liner.first {
    left: 42%;
  }
  .liner.second {
    left: 95%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .liner.third {
    left: 29%;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 1.125rem;
    font-weight: 400;
    height: 2.75rem;
    margin-left: 1.98em;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 2rem;
    margin-right: 0.9em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 2.25rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #677F90;
    display: block;
    height: 0.125rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.1875rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.9375rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    overflow-x: hidden;
    padding: 1.8em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms;
    transition: opacity 240ms, transform 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 18.125rem;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    border-bottom: 0.0625rem solid #677F90;
    padding: 0;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li:last-child {
    border-bottom: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #707173;
    display: block;
    font-size: 1.1875rem;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 3.125rem;
    padding: 0 0.72em;
    position: relative;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover {
    background-color: #677F90;
    color: #fff;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus.active,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover.active {
    color: #fff;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    color: #677F90;
    font-weight: 700;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
    margin-bottom: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul li {
    padding: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #707173;
    display: inline-block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.36em 1.8em;
    position: relative;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    z-index: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a::after {
    background-color: #677F90;
    bottom: 0.625rem;
    content: "";
    height: 0.0625rem;
    left: 1.8em;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-in-out, visibility 300ms ease-in-out;
    transition: right 300ms ease-in-out, visibility 300ms ease-in-out;
    visibility: hidden;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    background-color: #677F90;
    color: #fff;
    font-weight: 700;
    width: 100%;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover {
    color: #677F90;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus::after,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover::after {
    right: 1.8em;
    visibility: visible;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus.active,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover.active {
    background-color: #e1e5e9;
    color: #707173;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus.active::after,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover.active::after {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: block !important;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 600ms;
    transition: height 600ms;
  }
  .onlinetools_popup_overview {
    margin: 3.6em 0;
    position: relative;
  }
  .onlinetools_popup_overview .overview_title {
    margin-bottom: 0;
    padding-top: 0;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.9em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.9em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.45em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(255, 255, 255, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.8em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #677F90;
    border-radius: 0 0 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.51428571em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.2);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    height: 21.875rem;
    background-color: #eaeaea;
    cursor: move;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(234, 234, 234, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.8em 3.6em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.8em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.8em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.8em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.9em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.8em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #677F90;
    border: 0.0625rem solid #fff;
    border-radius: 0;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #fff;
    border: 0.0625rem solid #677F90;
    box-shadow: 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
    color: #566A77;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #fff;
    border: 0.0625rem solid #677F90;
    color: #566A77;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.875rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.9em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #eaeaea;
    border-radius: 0;
    color: #707173;
    margin-bottom: 0.9em;
    padding: 1.2em 1.8em;
  }
  .opening_times .title {
    padding-top: 0.9em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.7em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #d1d1d1;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    background: #677F90;
    border: 0.0625rem solid #fff;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  form[role="search"] button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out;
    transition: fill 300ms ease-in-out, transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    font-weight: 300;
    padding: 0.45em 0;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.6em;
  }
  form[role="search"] button[disabled] {
    background-color: #eaeaea;
    border-color: #dddddd;
    color: #f1f1f1;
  }
  form[role="search"] button[disabled] .icon {
    fill: #707173;
  }
  form[role="search"] button > span {
    width: 100%;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
    position: relative;
    z-index: 10;
    background: #fff;
    border-color: #677F90;
    color: #566A77;
  }
  form[role="search"] button:focus .icon {
    fill: #566A77;
  }
  form[role="search"] button .icon {
    height: 2.625rem;
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #d1d1d1;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0 #d1d1d1 inset;
    color: #707173;
    flex-basis: 100%;
    margin: 0;
    padding: 0 0.9em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    height: 2.75rem;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #677F90;
    box-shadow: 0 0 0.1875rem #677F90 inset, 0 0 0 0.1875rem rgba(103, 127, 144, 0.2);
    color: #707173;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.9em;
    padding-top: 0.45em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .toggle_search_box {
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    margin-left: 0;
    position: relative;
    width: 2.75rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .toggle_search_box a {
    display: -webkit-box;
    display: flex;
    fill: #707173;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .toggle_search_box a .icon {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .toggle_search_box a .icon svg {
    fill: #677F90;
    height: 1.875rem;
    width: 1.875rem;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.9em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(255, 255, 255, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    -webkit-box-align: center;
            align-items: center;
    background: #677F90;
    border: 0.0625rem solid #fff;
    border-radius: 0;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
    fill: #fff;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.25rem;
    position: absolute;
    right: 1.8em;
    top: 1.8em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #fff;
    border-color: #677F90;
    outline: 0;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover {
    background: #fff;
    border-color: #677F90;
    color: #566A77;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover .icon {
    fill: #566A77;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    width: 2.75rem;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 115%;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: -webkit-box;
    display: flex;
  }
  .sitemap h2 a {
    position: relative;
    text-decoration: none;
  }
  .sitemap h2 a::after {
    background-color: #677F90;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .sitemap h2 a:focus::after,
  .sitemap h2 a:hover::after {
    opacity: 1;
    right: 0;
  }
  .sitemap > ul {
    -webkit-column-gap: 0.9375rem;
            column-gap: 0.9375rem;
  }
  .sitemap > ul a {
    color: #707173;
    text-decoration: none;
  }
  .sitemap > ul a:hover,
  .sitemap > ul a:focus {
    text-decoration: underline;
  }
  .steuernews_preview {
    margin-bottom: 0.9em;
  }
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.8em;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    line-height: 0;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .steuernews_preview .image img {
    margin-right: 1.8em;
  }
  .steuernews_preview .photo .bg_image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 10rem;
    margin-right: 1.8em;
    width: 10rem;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .title {
    word-wrap: break-word;
  }
  .news_entity .hide_image_author,
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    float: right;
    margin-left: 1.8em;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
  }
  .steuernews_issues ul li {
    padding-left: 0;
  }
  .steuernews_issues a {
    background: #eaeaea;
    border-radius: 0;
    display: inline-block;
    margin: 0 0.9em 0.9em 0;
    padding: 0 1.8em;
    white-space: nowrap;
    line-height: 2.75rem;
  }
  .steuernews_disclaimer {
    clear: both;
  }
  .steuernews_sidebar {
    margin: 1.8em 0 0.9em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.9em;
  }
  .team_overview .team_overview_wrapper {
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .team_overview .entry {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 1.8em 0;
    width: 100%;
  }
  .team_overview .entry .photo,
  .team_overview .entry img {
    line-height: 0;
    margin-bottom: 0.9em;
    max-width: 20rem;
    width: 100%;
  }
  .team_overview .entry .title {
    letter-spacing: calc((1.125rem + 0.375 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.125rem;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    padding-top: 0;
    margin-bottom: 0.54em;
    width: 100%;
  }
  .team_overview .entry .wrapper_short_text {
    margin-bottom: 0.9em;
  }
  .team_overview .entry p {
    margin-bottom: 0;
    width: 100%;
  }
  .team_overview .entry .button {
    cursor: pointer;
    margin-top: 1.8em;
  }
  .team_overview .popup_info {
    background-color: rgba(255, 255, 255, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    visibility: hidden;
    z-index: 99999;
  }
  .team_overview .popup_info .photo_popup {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .team_overview .popup_info .photo_popup img {
    max-width: 18.75rem;
  }
  .team_overview .popup_info.show {
    opacity: 1;
    visibility: visible;
  }
  .team_overview .popup_info .close {
    background-color: #677F90;
    cursor: pointer;
    fill: #fff;
    height: 2.75rem;
    position: absolute;
    right: -1.375rem;
    top: -1.375rem;
    -webkit-transition: background-color 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: background-color 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: background-color 300ms ease-in-out, transform 300ms ease-in-out;
    transition: background-color 300ms ease-in-out, transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 2.75rem;
  }
  .team_overview .popup_info .close:focus,
  .team_overview .popup_info .close:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .team_overview .popup_info .info {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    height: 85%;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    width: 85%;
  }
  .team_overview .popup_info .info .info_wrapper {
    bottom: 1.8em;
    left: 1.8em;
    overflow-y: auto;
    padding-right: 1.8em;
    position: absolute;
    right: 0;
    top: 1.8em;
  }
  .team_overview .popup_info .info .title {
    letter-spacing: calc((1.5rem + 0.875 * (100vw - 20rem) / (73.75)) / 1000 * 20);
    font-size: 1.5rem;
  }
  .team_overview .popup_info .info .contact {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .team_overview .popup_info .info p {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0 0 0.9em 0;
  }
  .team_overview .popup_info .info p:last-child {
    margin: 0;
  }
  .teaser {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-top: 3.6em;
    position: relative;
  }
  .startpage .teaser {
    margin-top: 0;
  }
  .teaser.more_left .content .description_with_button {
    left: 0;
    top: -40%;
  }
  .teaser .content {
    position: relative;
  }
  .teaser .content .background_image {
    height: 17.5rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .teaser .content .background_image.image_to_left {
    left: -1.875rem;
  }
  .teaser .content .description_with_button {
    position: relative;
    z-index: 0;
  }
  .teaser .content .description_with_button::after {
    background-color: #677F90;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .teaser .content .description_with_button p,
  .teaser .content .description_with_button h1,
  .teaser .content .description_with_button h2,
  .teaser .content .description_with_button h3,
  .teaser .content .description_with_button h4,
  .teaser .content .description_with_button h5 {
    color: #fff;
  }
  .teaser .content .description_with_button p {
    font-weight: 300;
  }
  .teaser .content .description_with_button .button {
    margin-top: 1.8em;
  }
  .teaser .content .description_with_button .image_content {
    max-width: 4rem;
    margin-bottom: 0.9em;
    margin-right: 1.8em;
  }
  .teaser .content .description_with_button .with_image_content {
    display: -webkit-box;
    display: flex;
  }
  .teaser .content .description {
    margin: 1.8em 0;
  }
  .teaser .content h2,
  .teaser .content h3,
  .teaser .content h4,
  .teaser .content h5 {
    padding-top: 0;
  }
  .testimonial_overview .testimonial {
    display: -webkit-box;
    display: flex;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #eaeaea;
    border: 0.0625rem solid #d1d1d1;
    border-radius: 0;
    bottom: 100%;
    color: #707173;
    font-size: 85%;
    font-weight: 400;
    line-height: 1.8;
    padding: 0.9em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #eaeaea;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #d1d1d1;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .video_overview,
  .video_infolists_overview,
  .video_infolist_overview,
  .video_tutorial_overview {
    margin: 3.6em 0;
    position: relative;
  }
  .video_overview .overview_title,
  .video_infolists_overview .overview_title,
  .video_infolist_overview .overview_title,
  .video_tutorial_overview .overview_title {
    padding-top: 0;
  }
  .video_overview .entry,
  .video_infolists_overview .entry,
  .video_infolist_overview .entry,
  .video_tutorial_overview .entry {
    display: inline-block;
    margin-top: 1.8em;
  }
  .video_overview .entry .video_play .icon,
  .video_infolists_overview .entry .video_play .icon,
  .video_infolist_overview .entry .video_play .icon,
  .video_tutorial_overview .entry .video_play .icon {
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .video_overview .entry:hover .video_play .icon,
  .video_infolists_overview .entry:hover .video_play .icon,
  .video_infolist_overview .entry:hover .video_play .icon,
  .video_tutorial_overview .entry:hover .video_play .icon,
  .video_overview .entry:focus .video_play .icon,
  .video_infolists_overview .entry:focus .video_play .icon,
  .video_infolist_overview .entry:focus .video_play .icon,
  .video_tutorial_overview .entry:focus .video_play .icon {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  .video_overview .image,
  .video_infolists_overview .image,
  .video_infolist_overview .image,
  .video_tutorial_overview .image,
  .video_overview figure,
  .video_infolists_overview figure,
  .video_infolist_overview figure,
  .video_tutorial_overview figure {
    margin: 0;
  }
  .video_overview .image img,
  .video_infolists_overview .image img,
  .video_infolist_overview .image img,
  .video_tutorial_overview .image img,
  .video_overview figure img,
  .video_infolists_overview figure img,
  .video_infolist_overview figure img,
  .video_tutorial_overview figure img {
    border: 0.0625rem solid #e2e2e2;
    width: 100%;
  }
  .video_overview figcaption,
  .video_infolists_overview figcaption,
  .video_infolist_overview figcaption,
  .video_tutorial_overview figcaption {
    font-size: 100%;
  }
  .video_overview .video_play,
  .video_infolists_overview .video_play,
  .video_infolist_overview .video_play,
  .video_tutorial_overview .video_play {
    position: relative;
  }
  .video_overview .video_play .icon,
  .video_infolists_overview .video_play .icon,
  .video_infolist_overview .video_play .icon,
  .video_tutorial_overview .video_play .icon {
    fill: #677F90;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.8em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0.0625rem solid #e2e2e2;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  .video_preview {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 0.9em;
    width: 100%;
  }
  .video_preview > .title {
    text-align: center;
  }
  .video_preview .touch_link {
    -webkit-box-align: start;
            align-items: flex-start;
    align-self: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 2.7em 0;
    max-width: 75rem;
    position: relative;
    width: 100%;
  }
  .video_preview .touch_link.left {
    align-self: flex-start;
  }
  .video_preview .touch_link figure {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 0 1.8em 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .video_preview .touch_link figure img {
    border: 0.0625rem solid #e2e2e2;
  }
  .video_preview .touch_link .title {
    width: 100%;
  }
  .video_preview .touch_link .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
    width: 100%;
  }
  .video_preview .touch_link .teaser_text p {
    margin-bottom: 0;
    width: 100%;
  }
  .video_preview .touch_link .video_play {
    position: relative;
  }
  .video_preview .touch_link .video_play .icon {
    fill: #677F90;
    height: 3.5rem;
    left: 0;
    margin: -1.75rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .video_preview .touch_link:hover .video_play .icon,
  .video_preview .touch_link:focus .video_play .icon {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #677F90;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .video_infolist .button,
  .video_tutorial .button {
    margin-bottom: 1.8em;
  }
  .video_infolist .download_overview,
  .video_tutorial .download_overview {
    margin: 1.8em 0;
  }
  .video_preview_title_overview {
    margin-top: 1.8em;
    text-align: center;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.8em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper .overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: #eaeaea;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 1.8em;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: 1;
  }
  .video_wrapper .overlay a {
    display: block;
  }
  .video_wrapper.show_message {
    min-height: 25rem;
  }
  .video_wrapper.show_message .overlay {
    opacity: 1;
    visibility: visible;
  }
  .video_wrapper .text_wrapper {
    font-size: 85%;
    line-height: normal;
    overflow-y: auto;
    width: 100%;
  }
  .video_wrapper .button {
    margin-top: 1.8em;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.8em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .author_hor {
    right: calc(0.375rem + 0.875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .author_hor {
    right: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .author_hor {
    top: calc(0.1875rem + 0.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .author_hor {
    top: 0.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .button > span {
    min-height: calc(2.625rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .button.active > span {
    min-height: calc(2.625rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    width: calc(14.0625rem + 7.8125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    width: 21.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo {
    margin: 2.16em 0 1.8em 0;
  }
  .company_logo::after {
    display: none;
  }
}
@media screen and (min-width: 35rem) {
  .scrolled_down .company_logo {
    width: 11.5625rem;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  .scrolled_down .company_logo {
    width: calc(11.5625rem + 4.0625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  .scrolled_down .company_logo {
    width: 15.625rem;
  }
}
@media screen and (min-width: 30rem) {
  .cookie_banner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 30rem) {
  .cookie_banner p {
    text-align: left;
  }
}
@media screen and (min-width: 30rem) {
  .cookie_banner .close {
    margin-bottom: 0;
    margin-right: 0.9em;
  }
}
@media screen and (min-width: 20rem) {
  .cookie_banner .close > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .cookie_banner .close > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 75rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li > a {
    font-size: calc(0.8125rem + 0.40625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li > a {
    font-size: 1.21875rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li > a {
    padding: 0.63em 1.62em;
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li ul {
    padding: 2.16em 3.6em;
  }
}
@media screen and (min-width: 75rem) {
  footer .footer_content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .footer_content.margin_top {
    margin-top: 5.4em;
  }
}
@media screen and (min-width: 20rem) {
  footer .footer_content > .left {
    min-height: calc(21.25rem + 18.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .footer_content > .left {
    min-height: 40rem;
  }
}
@media screen and (min-width: 35rem) {
  footer .footer_content > .left {
    padding: 3.6em;
  }
}
@media screen and (min-width: 106.25rem) {
  footer .footer_content > .left {
    padding: 3.6em 3.6em 3.6em 0;
  }
}
@media screen and (max-width: 74.9375rem) {
  footer .footer_content > .left {
    margin-top: 17.5rem;
    position: relative;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  footer .footer_content > .left {
    margin-top: calc(17.5rem + 10 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  footer .footer_content > .left {
    margin-top: 27.5rem;
  }
}
@media screen and (min-width: 75rem) {
  footer .footer_content > .left {
    width: 50%;
  }
}
@media screen and (min-width: 20rem) {
  footer .footer_content > .left::after {
    left: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .footer_content > .left::after {
    left: -6.25rem;
  }
}
@media screen and (min-width: 106.1875rem) {
  footer .footer_content > .left::after {
    left: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 74.9375rem) {
  footer .footer_content > .left::after {
    right: -1.875rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  footer .footer_content > .left::after {
    right: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  footer .footer_content > .left::after {
    right: -6.25rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 106.1875rem) {
  footer .footer_content > .left::after {
    right: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (min-width: 75rem) {
  footer .footer_content > .left::after {
    right: calc(50% - 1.8em);
  }
}
@media screen and (min-width: 35rem) {
  footer .footer_content > .left .intro_text {
    bottom: 3.06em;
    display: block;
    top: 3.06em;
  }
}
@media screen and (max-width: 106.1875rem) {
  footer .footer_content > .left .intro_text {
    left: 0;
  }
}
@media screen and (min-width: 20rem) {
  footer .footer_content > .right {
    right: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .footer_content > .right {
    right: -6.25rem;
  }
}
@media screen and (min-width: 106.1875rem) {
  footer .footer_content > .right {
    right: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 74.9375rem) {
  footer .footer_content > .right {
    left: -1.875rem;
    min-height: 17.5rem;
    top: 0;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  footer .footer_content > .right {
    left: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  footer .footer_content > .right {
    left: -6.25rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 106.1875rem) {
  footer .footer_content > .right {
    left: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  footer .footer_content > .right {
    min-height: calc(17.5rem + 10 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  footer .footer_content > .right {
    min-height: 27.5rem;
  }
}
@media screen and (min-width: 75rem) {
  footer .footer_content > .right {
    bottom: 2.7em;
    left: calc(50% - 1.8em);
    top: 2.7em;
  }
}
@media screen and (min-width: 20rem) {
  .file {
    height: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file {
    height: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    line-height: calc(2.625rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    line-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    height: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    height: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button > span {
    min-height: calc(2.625rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"] {
    height: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"] {
    height: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: calc(2.625rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 20rem) {
  button {
    height: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  button {
    height: 3rem;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_entry {
    height: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_entry {
    height: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_radio > span {
    min-height: calc(2.625rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_radio > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  header .container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 24.375rem) {
  header .second {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
}
@media screen and (min-width: 47.5rem) {
  header .second {
    -webkit-box-pack: end;
            justify-content: flex-end;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 75rem) {
  header .login_portal,
  header .video_news {
    margin-left: 0.36em;
  }
}
@media screen and (min-width: 93.75rem) {
  header .login_portal,
  header .video_news {
    margin-left: 1.44em;
  }
}
@media screen and (min-width: 75rem) {
  header .login_portal .icon svg,
  header .video_news .icon svg {
    fill: #707173;
    height: 1.8125rem;
    width: 1.8125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  header {
    margin-bottom: 10.3125rem;
  }
  header .fixed_wrapper {
    -webkit-box-align: center;
            align-items: center;
    background-color: #fff;
    display: -webkit-box;
    display: flex;
    height: 10.3125rem;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    -webkit-transition: height 300ms ease-in-out;
    transition: height 300ms ease-in-out;
    width: 100%;
    z-index: 999;
  }
  header .fixed_wrapper.scrolled_down {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.15);
    height: 5.3125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  header {
    margin-bottom: 11.5625rem;
  }
  header .fixed_wrapper {
    height: 11.5625rem;
  }
  header .fixed_wrapper.scrolled_down {
    height: 6.5625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .intro_content {
    margin-bottom: 5.4em;
  }
}
@media screen and (min-width: 75rem) {
  .intro_content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 35rem) {
  .intro_content > .left {
    padding: 2.7em 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .intro_content > .left {
    padding: 3.6em 0;
  }
}
@media screen and (min-width: 75rem) {
  .intro_content > .left {
    min-height: 21.25rem;
    padding: 3.6em;
  }
}
@media screen and (min-width: 75rem) and (min-width: 20rem) {
  .intro_content > .left {
    min-height: calc(21.25rem + 18.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 75rem) and (min-width: 93.75rem) {
  .intro_content > .left {
    min-height: 40rem;
  }
}
@media screen and (min-width: 106.25rem) {
  .intro_content > .left {
    padding: 3.6em 3.6em 3.6em 0;
  }
}
@media screen and (max-width: 74.9375rem) {
  .intro_content > .left {
    margin-top: 17.5rem;
    position: relative;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  .intro_content > .left {
    margin-top: calc(17.5rem + 10 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  .intro_content > .left {
    margin-top: 27.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .intro_content > .left {
    width: 60%;
  }
}
@media screen and (min-width: 93.75rem) {
  .intro_content > .left {
    width: 50%;
  }
}
@media screen and (min-width: 93.75rem) {
  .intro_content > .left .left_inner {
    max-width: 38rem;
  }
}
@media screen and (min-width: 20rem) {
  .intro_content > .left::after {
    left: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (min-width: 93.75rem) {
  .intro_content > .left::after {
    left: -6.25rem;
  }
}
@media screen and (min-width: 106.1875rem) {
  .intro_content > .left::after {
    left: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 74.9375rem) {
  .intro_content > .left::after {
    right: -1.875rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  .intro_content > .left::after {
    right: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  .intro_content > .left::after {
    right: -6.25rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 106.1875rem) {
  .intro_content > .left::after {
    right: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (min-width: 75rem) {
  .intro_content > .left::after {
    right: calc(40% - 1.8em);
  }
}
@media screen and (min-width: 93.75rem) {
  .intro_content > .left::after {
    right: calc(50% - 1.8em);
  }
}
@media screen and (min-width: 35rem) {
  .intro_content > .left .scroll_down_to_sidebar {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    margin-top: 1.8em;
  }
}
@media screen and (min-width: 75rem) {
  .intro_content > .left .intro_text {
    bottom: 3.06em;
    display: block;
    top: 3.06em;
  }
}
@media screen and (max-width: 106.1875rem) {
  .intro_content > .left .intro_text {
    left: 0;
  }
}
@media screen and (min-width: 20rem) {
  .intro_content > .right {
    right: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (min-width: 93.75rem) {
  .intro_content > .right {
    right: -6.25rem;
  }
}
@media screen and (min-width: 106.1875rem) {
  .intro_content > .right {
    right: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 74.9375rem) {
  .intro_content > .right {
    left: -1.875rem;
    min-height: 17.5rem;
    top: 0;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  .intro_content > .right {
    left: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  .intro_content > .right {
    left: -6.25rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 106.1875rem) {
  .intro_content > .right {
    left: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  .intro_content > .right {
    min-height: calc(17.5rem + 10 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  .intro_content > .right {
    min-height: 27.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .intro_content > .right {
    bottom: 2.7em;
    left: calc(60% - 1.8em);
    top: 2.7em;
  }
}
@media screen and (min-width: 93.75rem) {
  .intro_content > .right {
    left: calc(50% - 1.8em);
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3.6em;
            column-gap: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (max-width: 61.8125rem) {
  .hideme_smaller_xlarge {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .intro_text .text {
    font-size: calc(0.9375rem + 0.21875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .intro_text .text {
    font-size: 1.15625rem;
  }
}
@media screen and (max-width: 106.1875rem) {
  .intro_text {
    display: none;
  }
}
@media screen and (min-width: 106.25rem) {
  .intro_text.left {
    left: calc((-100vw + 1500rem / 16) / 4);
  }
}
@media screen and (min-width: 160rem) {
  .intro_text.left {
    left: calc((-2560rem / 16 + 1500rem / 16) / 4);
  }
}
@media screen and (min-width: 106.25rem) {
  .intro_text.right {
    right: calc((-100vw + 1500rem / 16) / 4);
  }
}
@media screen and (min-width: 160rem) {
  .intro_text.right {
    right: calc((-2560rem / 16 + 1500rem / 16) / 4);
  }
}
@media screen and (min-width: 20rem) {
  .hidme_intro_text {
    font-size: calc(0.875rem + 0.15625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .hidme_intro_text {
    font-size: 1.03125rem;
  }
}
@media screen and (min-width: 106.25rem) {
  .hidme_intro_text {
    display: none;
  }
}
@media screen and (max-width: 61.8125rem) {
  .actual_news_video .content {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding-top: 17.5rem;
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 20rem) {
  .actual_news_video .content {
    padding-top: calc(17.5rem + 22.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 93.75rem) {
  .actual_news_video .content {
    padding-top: 40rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .actual_news_video .content {
    margin-bottom: 17.5rem;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 20rem) {
  .actual_news_video .content {
    margin-bottom: calc(17.5rem + 22.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 93.75rem) {
  .actual_news_video .content {
    margin-bottom: 40rem;
  }
}
@media screen and (min-width: 20rem) {
  .actual_news_video .content .background_image {
    height: calc(17.5rem + 22.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .actual_news_video .content .background_image {
    height: 40rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .actual_news_video .content .background_image {
    top: 100%;
  }
}
@media screen and (max-width: 74.9375rem) {
  .actual_news_video .content .background_image {
    left: -1.875rem;
    right: -1.875rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  .actual_news_video .content .background_image {
    left: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  .actual_news_video .content .background_image {
    left: -6.25rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 106.1875rem) {
  .actual_news_video .content .background_image {
    left: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  .actual_news_video .content .background_image {
    right: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  .actual_news_video .content .background_image {
    right: -6.25rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 106.1875rem) {
  .actual_news_video .content .background_image {
    right: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (min-width: 20rem) {
  .actual_news_video .content .background_image .icon {
    height: calc(3.75rem + 3.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .actual_news_video .content .background_image .icon {
    height: 7.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .actual_news_video .content .background_image .icon {
    width: calc(3.75rem + 3.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .actual_news_video .content .background_image .icon {
    width: 7.5rem;
  }
}
@media screen and (min-width: 61.875rem) and (max-width: 93.6875rem) {
  .actual_news_video .content .background_image .icon {
    left: 35%;
  }
}
@media screen and (max-width: 61.8125rem) {
  .actual_news_video .content .description_with_button {
    padding: 3.6em 0;
  }
  .actual_news_video .content .description_with_button::after {
    right: -1.875rem;
    left: -1.875rem;
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 20rem) {
  .actual_news_video .content .description_with_button::after {
    right: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 93.75rem) {
  .actual_news_video .content .description_with_button::after {
    right: -6.25rem;
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 106.1875rem) {
  .actual_news_video .content .description_with_button::after {
    right: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 20rem) {
  .actual_news_video .content .description_with_button::after {
    left: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 93.75rem) {
  .actual_news_video .content .description_with_button::after {
    left: -6.25rem;
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 106.1875rem) {
  .actual_news_video .content .description_with_button::after {
    left: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (min-width: 61.875rem) {
  .actual_news_video .content .description_with_button {
    padding: 3.6em 3.6em;
    position: absolute;
    right: 0%;
    top: 0;
    width: 45%;
  }
}
@media screen and (min-width: 93.75rem) {
  .actual_news_video .content .description_with_button {
    width: 35%;
  }
}
@media screen and (min-width: 61.875rem) {
  .actual_news_video .content .description_wrapper {
    padding-bottom: 2.7em;
    width: 45%;
  }
}
@media screen and (min-width: 93.75rem) {
  .actual_news_video .content .description_wrapper {
    width: 35%;
  }
}
@media screen and (min-width: 61.875rem) {
  .actual_news_video .content .description {
    margin: 1.8em 0 0 0;
    min-height: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .liner {
    height: calc(6.25rem + 6.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .liner {
    height: 12.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .liner {
    width: calc(10rem + 7.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .liner {
    width: 17.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 74.9375rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_3 {
    bottom: 1rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    height: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    width: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_content .content {
    top: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .open_street_map .map {
    height: calc(21.875rem + 12.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.8em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.8em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button > span {
    min-height: calc(2.625rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button {
    width: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button {
    width: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button .icon {
    height: calc(2.625rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button .icon {
    height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  input[type="search"] {
    height: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  input[type="search"] {
    height: 3rem;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_search_box {
    margin-left: 0.18em;
  }
}
@media screen and (min-width: 93.75rem) {
  .toggle_search_box {
    margin-left: 0.36em;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_search_box a .icon svg {
    fill: #707173;
    height: 1.3125rem;
    width: 1.3125rem;
  }
}
@media screen and (min-width: 20rem) {
  .search_box form[role="search"] button {
    width: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .search_box form[role="search"] button {
    width: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .sitemap > ul {
    -webkit-column-gap: calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
            column-gap: calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 47.5rem) {
  .sitemap > ul {
    -webkit-column-count: 2;
            column-count: 2;
  }
}
@media screen and (min-width: 93.75rem) {
  .sitemap > ul {
    -webkit-column-gap: 3.125rem;
            column-gap: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_issues a {
    line-height: calc(2.75rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_issues a {
    line-height: 3rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  .team_overview .entry .photo,
  .team_overview .entry img {
    margin-bottom: 0;
    margin-right: 1.8em;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview .entry .title {
    font-size: calc(1.125rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry .title {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 30rem) {
  .team_overview .popup_info .info {
    width: 23.75rem;
  }
}
@media screen and (min-width: 30rem) and (min-width: 20rem) {
  .team_overview .popup_info .info {
    width: calc(23.75rem + 25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 30rem) and (min-width: 93.75rem) {
  .team_overview .popup_info .info {
    width: 48.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview .popup_info .info .title {
    font-size: calc(1.5rem + 0.875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .popup_info .info .title {
    font-size: 2.375rem;
  }
}
@media screen and (min-width: 30rem) {
  .team_overview .popup_info .info .contact {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 75rem) {
  .teaser.more_margin_right {
    padding-right: 5.4em;
  }
}
@media screen and (min-width: 61.875rem) {
  .teaser.more_left .content .description_with_button {
    left: 52%;
  }
}
@media screen and (max-width: 61.8125rem) {
  .teaser .content {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    padding-top: 17.5rem;
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 20rem) {
  .teaser .content {
    padding-top: calc(17.5rem + 22.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 93.75rem) {
  .teaser .content {
    padding-top: 40rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .teaser .content {
    margin-bottom: 17.5rem;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 20rem) {
  .teaser .content {
    margin-bottom: calc(17.5rem + 22.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 93.75rem) {
  .teaser .content {
    margin-bottom: 40rem;
  }
}
@media screen and (min-width: 20rem) {
  .teaser .content .background_image {
    height: calc(17.5rem + 22.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser .content .background_image {
    height: 40rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .teaser .content .background_image {
    top: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .teaser .content .background_image.image_to_left {
    left: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser .content .background_image.image_to_left {
    left: -6.25rem;
  }
}
@media screen and (min-width: 106.1875rem) {
  .teaser .content .background_image.image_to_left {
    left: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 74.9375rem) {
  .teaser .content .background_image {
    right: -1.875rem;
    left: -1.875rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  .teaser .content .background_image {
    right: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  .teaser .content .background_image {
    right: -6.25rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 106.1875rem) {
  .teaser .content .background_image {
    right: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 20rem) {
  .teaser .content .background_image {
    left: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 93.75rem) {
  .teaser .content .background_image {
    left: -6.25rem;
  }
}
@media screen and (max-width: 74.9375rem) and (min-width: 106.1875rem) {
  .teaser .content .background_image {
    left: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 61.8125rem) {
  .teaser .content .description_with_button {
    padding: 3.6em 0;
  }
  .teaser .content .description_with_button::after {
    right: -1.875rem;
    left: -1.875rem;
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 20rem) {
  .teaser .content .description_with_button::after {
    right: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 93.75rem) {
  .teaser .content .description_with_button::after {
    right: -6.25rem;
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 106.1875rem) {
  .teaser .content .description_with_button::after {
    right: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 20rem) {
  .teaser .content .description_with_button::after {
    left: calc(-2 * (0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)));
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 93.75rem) {
  .teaser .content .description_with_button::after {
    left: -6.25rem;
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 106.1875rem) {
  .teaser .content .description_with_button::after {
    left: calc((100vw - (100rem - 3.125rem * 2)) / -2);
  }
}
@media screen and (min-width: 61.875rem) {
  .teaser .content .description_with_button {
    left: 50%;
    padding: 3.6em 3.6em;
    position: absolute;
    top: 0;
    width: 45%;
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser .content .description_with_button {
    left: 43%;
  }
}
@media screen and (min-width: 61.875rem) {
  .teaser .content .description_with_button .image_content {
    max-width: 5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .teaser .content .description_wrapper {
    padding-bottom: 2.7em;
    width: 45%;
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser .content .description_wrapper {
    width: 35%;
  }
}
@media screen and (min-width: 61.875rem) {
  .teaser .content .description {
    margin: 1.8em 0 0 0;
    min-height: 3.6em;
  }
}
@media screen and (min-width: 61.875rem) {
  .video_preview .touch_link {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .video_preview .touch_link figure {
    margin-bottom: 0;
    margin-right: 1.8em;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.9em;
    max-width: 11.25rem !important;
  }
  .company_logo img {
    display: block;
  }
  hr {
    display: none !important;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.8em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.9em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.8em;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.8em 0 0;
    padding: 0.9em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
